import React, {memo, useMemo, lazy, Suspense, useEffect} from 'react'
import { Loading } from 'components/shared'
import {useDispatch, useSelector} from 'react-redux'
import {
	LAYOUT_TYPE_CLASSIC,
	LAYOUT_TYPE_MODERN,
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_BLANK
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import useWorkSpace from "../../utils/hooks/useWorkSpace";
import {setLoading, setLoading2, setLocalLoading} from "../../store/base/commonSlice";
import {getWorkSpaces, logOutWorkSpace, setStatus} from "../../store/workSpace/workSpaceSlice";
import {getWorkSpacesList} from "../../services/WorkSpaceService";

const layouts = {
	[LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
	[LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
	[LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {

	const layoutType = useSelector((state) => state.theme.layout.type)
	const { authenticated } = useAuth()
	const dispatch = useDispatch()
	const workSpaceState = useSelector(state => state.workSpace)
	const loading = useSelector(state => state.base.common.loading)
	const loading2 = useSelector(state => state.base.common.loading2)
	const localLoading = useSelector(state => state.base.common.localLoading)

	useDirection()

	useLocale()

	useEffect(() => {
		dispatch(setLoading(true))
		getWorkSpacesList().then(resp => {
			dispatch(getWorkSpaces(resp?.data?.data || []))
			dispatch(setLoading(false))
			dispatch(setLoading2(false))
			dispatch(setLocalLoading(false))
		}).catch(err => {
			dispatch(getWorkSpaces([]))
			dispatch(setStatus(false))
			dispatch(setLoading(false))
			dispatch(setLoading2(false))
			dispatch(setLocalLoading(false))
		})
	}, [authenticated])

	const AppLayout = useMemo(() => {
		if (authenticated) {
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated])


	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={loading} />
				</div>
			}
		>
			{loading ?
			<div className="flex flex-auto flex-col h-[100vh]">
				<Loading loading={loading} />
			</div> :
				workSpaceState.apiReq ?
						<>
							<Loading loading={loading2 || localLoading} type='cover'>
								<AppLayout />
							</Loading>
						</>
					:
						<div className="flex flex-auto flex-col h-[100vh]">
							<Loading loading={true}/>
						</div>
			}
		</Suspense>
	)
}

export default memo(Layout)
