const appConfig = {
    apiPrefix: 'https://api.cognera.io/',
    authenticatedEntryPath: '/gather',
    authenticatedWelcomePath: '/setup/workspace',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: false
}

export default appConfig
