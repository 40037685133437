import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
	id: '',
	firstName: '',
	lastName: '',
	email: ''
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.id = action.payload.id
			state.firstName = action.payload.first_name
			state.lastName = action.payload.last_name
			state.email = action.payload.email
		},
        userLoggedOut: () => initialState,
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
