import ApiService from "./ApiService"

export async function getWorkSpacesList (data) {
	return ApiService.fetchData({
		url: '/workspace',
		method: 'get',
		data
	})
}

export async function getWorkSpaceItem (id) {
	return ApiService.fetchData({
		url: `/workspace/${id}`,
		method: 'get'
	})
}

export async function createWorkSpaceItem (data) {
	return ApiService.fetchData({
		url: '/workspace',
		method: 'post',
		data
	})
}

export async function updateWorkSpaceItem (id, data) {
	return ApiService.fetchData({
		url: `/workspace/${id}`,
		method: 'put',
		data
	})
}

export async function deleteWorkSpaceItem (data) {
	return ApiService.fetchData({
		url: `/workspace/${data.id}`,
		method: 'delete',
	})
}

export async function getWorkspaceMembers (id) {
	return ApiService.fetchData({
		url: `/workspace/${id}/members`,
		method: 'get',
	})
}

export async function inviteUser (data) {
	return ApiService.fetchData({
		url: `/user/invite`,
		method: 'post',
		data
	})
}
