import BaseService from './BaseService'
import store from "../store";
import { onSignOutSuccess, setToken } from "../store/auth/sessionSlice";
import deepParseJson from "../utils/deepParseJson";
import {PERSIST_STORE_NAME} from "../constants/app.constant";
import {setLoading2} from "../store/base/commonSlice";
import {useSelector} from "react-redux";

const unauthorizedCode = [401]
let url            = null;

const ApiService = {
    fetchData(param, load = false) {
        if (!url || load) {
            return new Promise((resolve, reject) => {
                BaseService(param).then(response => {
                    resolve(response)
                    url = null
                }).catch(errors => {
                    const { response } = errors

                    if (response && unauthorizedCode.includes(response.status)) {
                        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
                        const persistData = deepParseJson(rawPersistData)
                        const refreshToken = persistData.auth.session.refreshToken
                        store.dispatch(setLoading2(true))

                        url = param.url;
                        BaseService({
                            url: '/auth/refresh',
                            method: 'post',
                            data: {
                                'token': refreshToken
                            }
                        }).then(response => {
                            store.dispatch(setToken(response.data.data))
                            setTimeout(() => {
                                this.fetchData(param, true).then(resp => {
                                    url = '';
                                    store.dispatch(setLoading2(false))
                                    resolve(resp)
                                }).catch(err => {
                                    url = '';
                                    store.dispatch(setLoading2(false))
                                    reject(err)
                                })
                            }, 1000)
                        }).catch(errors => {
                            url = '';
                            store.dispatch(setLoading2(false))
                            store.dispatch(onSignOutSuccess())
                            reject(errors)
                        })
                    }
                    else {
                        url = null
                        reject(response)
                    }
                })
            })
        }
        else {
            return 'salammmmmmamsam'
        }
    }
}

export default ApiService
