import { createSlice } from '@reduxjs/toolkit'

export const workSpaceSlice = createSlice({
	name: 'workSpaces',
	initialState: {
		workSpaces: [],
		workSpacesSelected: {},
		apiReq: false,
	},
	reducers: {
		getWorkSpaces: (state, action) => {
			state.workSpaces = action.payload;
			state.workSpaceStatus = !!action.payload.length;
			state.workSpacesSelected =
				(localStorage.workSpacesSelected && JSON.parse(localStorage.workSpacesSelected).id && !!(action.payload || []).find(el => el.id === JSON.parse(localStorage.workSpacesSelected).id)) ?
					JSON.parse(localStorage.workSpacesSelected)
					:
					{}
			;
			state.apiReq = true;
			localStorage.workSpacesSelected =
				(localStorage.workSpacesSelected && JSON.parse(localStorage.workSpacesSelected).id && !!(action.payload || []).find(el => el.id === JSON.parse(localStorage.workSpacesSelected).id)) ?
					localStorage.workSpacesSelected
					:
					JSON.stringify({})
			;
		},
		logOutWorkSpace: (state) => {
			state.workSpaces = []
			delete state.workSpaceStatus
			state.workSpacesSelected = {}
			state.apiReq = false;
			localStorage.workSpacesSelected = JSON.stringify({})
		},
		createWorkSpaceAction: (state, action) => {
			state.workSpaceStatus = true
			state.workSpacesSelected = action.payload
		},
		deleteWorkSpaceAction: (state, action) => {
			state.workSpaceStatus = true
			state.workSpacesSelected = action.payload
		},
		changeWorkSpaceAction: (state, action) => {
			state.workSpaceStatus = true
			state.workSpacesSelected = (state.workSpaces || []).find(el => el.id === action.payload.id) || {}
			localStorage.workSpacesSelected = JSON.stringify((state.workSpaces || []).find(el => el.id === action.payload.id)) || JSON.stringify({})
		},
		setStatus: (state, action) => {
			if (action.payload) {
				state.workSpaceStatus = action.payload
			}
			else {
				delete state.workSpaceStatus
			}
		}
	},
})

export const { getWorkSpaces, logOutWorkSpace, createWorkSpaceAction, changeWorkSpaceAction, setStatus } = workSpaceSlice.actions

export default workSpaceSlice.reducer
