import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/auth/login',
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/auth/register',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/auth/forgot',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/user/reset',
        method: 'post',
        data
    })
}

export async function apiCheckToken (data) {
    return ApiService.fetchData({
        url: '/confirmation/check',
        method: 'post',
        data
    })
}

export async function apiConfirmToken (data) {
    return ApiService.fetchData({
        url: '/user/confirm',
        method: 'post',
        data
    })
}

export async function apiConfirmInviteToken (data) {
    return ApiService.fetchData({
        url: '/confirmation/check',
        method: 'post',
        data
    })
}

export async function apiConfirmInviteInfo (data) {
    return ApiService.fetchData({
        url: '/confirmation/invitation',
        method: 'post',
        data
    })
}

export async function apiInviteUser (data) {
    return ApiService.fetchData({
        url: '/auth/invitation',
        method: 'post',
        data
    })
}

export async function apiMemberAccept (data) {
    return ApiService.fetchData({
        url: '/member/accept',
        method: 'post',
        data
    })
}
