import { createSlice } from '@reduxjs/toolkit'

export const connectorSlice = createSlice({
	name: 'workSpaces',
	initialState: {
		oauth_response: ''
	},
	reducers: {
		setOauthResponse: (state, action) => {
			state.oauth_response = action.payload;
		}
	},
})

export const { setOauthResponse } = connectorSlice.actions

export default connectorSlice.reducer
