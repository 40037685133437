import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
	loading: true,
	loading2: true,
	localLoading: true,
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setLoading2: (state, action) => {
			state.loading2 = action.payload
		},
		setLocalLoading: (state, action) => {
			state.localLoading = action.payload
		}
	},
})

export const { setCurrentRouteKey, setLoading, setLoading2, setLocalLoading } = commonSlice.actions

export default commonSlice.reducer
