import {useDispatch} from 'react-redux'
import { getWorkSpaces, createWorkSpaceAction } from 'store/workSpace/workSpaceSlice'
import {createWorkSpaceItem, deleteWorkSpaceItem, getWorkSpacesList} from "services/WorkSpaceService";
import {REDIRECT_URL_KEY} from "../../constants/app.constant";
import appConfig from "../../configs/app.config";
import {useNavigate} from "react-router-dom";
import useQuery from "./useQuery";

function useWorkSpace() {

    const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

    const workSpace = async () => {
        try {
			const resp = await getWorkSpacesList()
			if (resp.data.data) {
				dispatch(getWorkSpaces(resp.data.data))
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (errors) {
			dispatch(getWorkSpaces([]))
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const createWorkSpace = async (values) => {
        try {
			const resp = await createWorkSpaceItem({name: values.workspace_name, slug: values.workspace_slug, organization_id: values.workspace_organization})

			if (resp.data) {
				const getResp = await workSpace()
				if (getResp.status === 'success') {
					dispatch(createWorkSpaceAction(resp.data.data))
					const redirectUrl = query.get(REDIRECT_URL_KEY)
					navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
					return {
						status: 'success',
						message: ''
					}
				}
				else {
					return {
						status: 'failed',
						message: 'err'
					}
				}
			}
		} catch (errors) {
			console.log('err', errors)
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const deleteWorkSpace = async (values) => {
        try {
			const resp = await deleteWorkSpaceItem(values)
			if (resp.status) {
				const getResp = await workSpace()
				if (getResp.status === 'success') {
					const redirectUrl = query.get(REDIRECT_URL_KEY)
					navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
					return {
						status: 'success',
						message: ''
					}
				}
				else {
					return {
						status: 'failed',
						message: 'err'
					}
				}
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    return {
		workSpace, createWorkSpace, deleteWorkSpace
    }
}

export default useWorkSpace
